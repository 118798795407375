import {
    ALL_ORDER_ENDPOINT,
    DELIVERY_STATUS,
    ORDER_DETAIL,
    ORDER_STATUS,
    RETURN_ORDER,
    RETURN_REASON,
} from '@/constants/endpoints/orderEndPoints';
import { getApi, postApi } from '@/scripts/api-services';
import userService from '@/services/userService';
import { createAsyncThunk } from '@reduxjs/toolkit';

/*--------------------All Product Data-----------------*/

export const getOrders: any = createAsyncThunk(
    'order/getOrders',
    async (reqData: any) => {
        try {
            const response = await postApi(ALL_ORDER_ENDPOINT, reqData);
            return response;
        } catch (error) {
            console.log('Error fetch  API', error);
            return Promise.reject(error);
        }
    }
);

/*--------------------Return And Refund-----------------*/

export const returnOrder: any = createAsyncThunk(
    'order/return',
    async ({ orderId, returnReason }: any, {getState}) => {
        try {
            const userDetail = await userService.getUserProfile();
            const userId = userDetail?._id;
            const response = await postApi(`${RETURN_ORDER}/${orderId}`, {
                userId,
                reason: returnReason,
            });
            return response;
        } catch (error) {
            console.error('Error in placing a request for return: ', error);
            return Promise.reject(error);
        }
    }
);

/*--------------------Track order status-----------------*/

export const getOrderStatus: any = createAsyncThunk(
    'order/getOrderStatus',
    async (reqData: any, thunkAPI) => {
        try {
             const {order_store} = thunkAPI.getState();
             const {orderData} = order_store;
             const { pidgeOrderStatus, orderId } = reqData;
             const requiredOrder = orderData.find((order) => {
                 return order.orderId === orderId;
                });
                
                const pidgeOrderId = requiredOrder?.pidgeOrderStatus?.pidgeOrderId;
                const orderStatusCheckedAt = new Date();
                
                if (pidgeOrderId) {   
                    // const response = await getApi(`${ORDER_STATUS}/${pidgeOrderId}`);
                    const response = await getApi(`${DELIVERY_STATUS}/${orderId}`);
                    
                    return {...response.data, orderId, orderStatusCheckedAt, type: 'pidge-detail'};
                    // return {...response.data.data, orderId, orderStatusCheckedAt, type: 'pidge-detail'};
                }

            const response = await getApi(`${ORDER_DETAIL}/${orderId}`);
            return {
                newOrderData: response.data.data,
                orderId,
                orderStatusCheckedAt,
                type: 'order-detail',
            };
            

        } catch (error) {
            console.log('Error fetch  API', error);
            return Promise.reject(error);
        }
    }
);
