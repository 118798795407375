import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StoreState = {
    stores: {
        _id: string;
        name: string;
        pincode: string;
        state: string;
        mobile: string;
        address: string;
    }[];
};

const initialState: StoreState = {
    stores: [],
};

const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        setStores: (state, action: PayloadAction<{data: {list: StoreState['stores']}}>) => {
            const  { payload } = action;
            const {data} = payload;
            state.stores = data.list;
        },
    },
});

export const { setStores } = storeSlice.actions;

export default storeSlice.reducer;
