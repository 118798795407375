import { createSlice } from '@reduxjs/toolkit';
import { getWistlistData } from './actions/wishlist-action';

interface WishListState {
    isLoading: boolean;
    status: string | null;
    wishListData: any | null;
    userId?: string;
    count: number;
    currentPage: number;
}

const initialState: WishListState = {
    isLoading: false,
    wishListData: [],
    status: '',
    userId: '',
    count: 0,
    currentPage: 1,
};

export const wishListSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        SetUserId: (state, { payload }) => {
            state.userId = payload;
        },
        SetWishlistCurrentPage: (state, { payload }) => {
            state.currentPage = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getWistlistData.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            // state.wishListData = payload?.data?.data?.data;
            if (Number(state.currentPage) === 1) {
                state.wishListData = payload?.data?.data?.data;
            } else {
                state.wishListData = [
                    ...state.wishListData,
                    ...payload?.data?.data?.data,
                ];
            }
            state.status = 'fulfilled';
            state.count = payload?.data?.data?.count;
        });
        builder.addCase(getWistlistData.pending, (state) => {
            state.status = 'pending';
            state.isLoading = true;
        });
        builder.addCase(getWistlistData.rejected, (state) => {
            state.status = 'rejected';
            state.isLoading = true;
        });
    },
});

export const { SetUserId, SetWishlistCurrentPage } = wishListSlice.actions;

export default wishListSlice.reducer;
