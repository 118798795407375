import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    getAddressData,
    getAddressDetails,
    getCityData,
    getStatesData,
} from './actions/address-action';

interface Addresstate {
    isLoading: boolean;
    status: string | null;
    // address list
    addressData: [];
    billingAddressData: [];
    statesData: [];
    cityData: [];
    formData: any;
    editFormData: any;
    showAddressForm: boolean;
    showEditAddressForm: boolean;
    getSingleAddress: [];
}

const initialState: Addresstate = {
    isLoading: false,
    addressData: [],
    billingAddressData: [],
    statesData: [],
    cityData: [],
    status: '',
    formData: {
        isDefault: false,
    },
    showAddressForm: false,
    showEditAddressForm: false,
    getSingleAddress: [],
    editFormData: {
        isDefault: false,
    },
};

export const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        setShowAddressForm: (state, action) => {
            state.showAddressForm = action.payload;
        },
        setShowEditAddress: (state, action) => {
            state.showEditAddressForm = action.payload;
        },
        updateFormData: (state, { payload }: PayloadAction<any>) => {
            state.formData[payload.key] = payload.value;
        },
        resetFormData: (state) => {
            state.formData = {
                isDefault: false,
            };
        },
        setEditFormData: (state, { payload }: PayloadAction<any>) => {
            console.log(payload, 'payload');
            state.editFormData[payload.key] = payload.value;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getAddressData.pending, (state, { payload }) => {
            state.isLoading = true;
            state.status = 'pending';
        });
        builder.addCase(getAddressData.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.addressData = payload?.data;
            if (payload?.data?.length > 0) {
                const data = payload.data;
                const filterData = data?.filter(
                    (item: any) => item.isBilling === true
                );
                state.billingAddressData = filterData;
            }

            if (payload?.data?.length === 0) {
                state.formData.isDefault = true;
            }

            state.status = 'fulfilled';
        });
        builder.addCase(getAddressData.rejected, (state, { payload }) => {
            state.isLoading = true;
            state.status = 'rejected';
        });
        builder.addCase(getStatesData.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.statesData = payload;
            state.status = 'fulfilled';
        });
        builder.addCase(getCityData.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.cityData = payload.data;
            state.status = 'fulfilled';
        });
        builder.addCase(getAddressDetails.fulfilled, (state, { payload }) => {
            // console.log("Address Payload------------", payload)
            state.isLoading = false;
            state.status = 'fulfilled';
            state.getSingleAddress = payload?.data;
            state.editFormData = {
                id: payload?.data?._id,
                houseNo: payload?.data?.houseNo,
                streetName: payload.data.streetName,
                pincode: payload?.data?.pincode,
                addressType: payload?.data?.addressType,
                stateId: payload?.data?.stateId._id,
                state: payload.data.stateId.name,
                cityId: payload?.data?.cityId._id,
                city: payload.data.cityId.name,
                fullName: payload?.data?.fullName,
                mobile: payload?.data?.mobile,
                email: payload?.data?.email,
                landmark: payload?.data?.landmark,
                isDefault: payload?.data?.isDefault,
                isBilling: payload.data.isBilling,
            };
        });
    },
});

export const {
    setEditFormData,
    setShowEditAddress,
    updateFormData,
    resetFormData,
    setShowAddressForm,
} = addressSlice.actions;

export default addressSlice.reducer;
