import axios from 'axios';
import * as userEndpoints from '@/constants/endpoints/userEndpoints';
import toast from 'react-hot-toast';
import { API } from '@/scripts/api-services';

const userService = {
    getUserProfile: async (): Promise<any> => {
        try {
            const response = await API.get(
                userEndpoints.GET_USER_PROFILE_ENDPOINT
            );
            return response?.data?.data;
        } catch (error) {
            // toast.error("Something went wrong!");
            throw error;
        }
    },

    updateUserProfile: async (data: any): Promise<any> => {
        try {
            const response = await API.patch(
                userEndpoints.UPDATE_USER_PROFILE_ENDPOINT,
                data
            );

            return response?.data?.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 422) {
                throw error.response;
            } else {
                // toast.error("Something went wrong!");
                throw error;
            }
        }
    },

    updateUserProfileImage: async (data: any): Promise<any> => {
        try {
            const response = await API.patch(
                userEndpoints.UPDATE_USER_PROFILE_ENDPOINT,
                data,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            return response.data.data;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 422) {
                throw error.response;
            } else {
                //  toast.error("Something went wrong!");
                throw error;
            }
        }
    },

    deleteUserAccount: async (): Promise<any> => {
        try {
            const response = await API.post(userEndpoints.DELETE_USER_ACCOUNT);
            return response;
        } catch (error) {
            // toast.error("Something went wrong!");
            throw error;
        }
    },
};

export default userService;
