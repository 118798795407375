import { createSlice } from '@reduxjs/toolkit';
import {
    elasticSearchData,
    getBannerData,
    getBestSellingCategoryProduct,
    getBestSellingData,
    getBestSellingProduct,
    getConsiousWallProduct,
    getCrazyDealsProduct,
    getFeaturedData,
    getFeaturedProduct,
    getInroducingProduct,
    getTopBrandsData,
    ManipualteBestSellingData,
    searchData,
} from './actions/home-action';

interface BrandId {
    isLoading: boolean;
    topBrandData?: [];
    search?: string;
    searchSuggestions?: [] | undefined;
    featuredData?: [];
    bestSellingProduct?: [];
    bestSellingData?: [];
    bestSellingCategory?: [];
    featuredProduct?: [];
    consiousWallProduct?: [];
    introducingProduct?: [];
    crazyDeals?: [];
    mainBanners?: [];
}

const initialState: BrandId = {
    isLoading: false,
    topBrandData: [],
    featuredData: [],
    bestSellingData: [],
    bestSellingCategory: [],
    bestSellingProduct: [],
    search: '',
    searchSuggestions: [],
    featuredProduct: [],
    consiousWallProduct: [],
    introducingProduct: [],
    crazyDeals: [],
    mainBanners: [],
};

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        SetElasticSearch: (state, { payload }) => {
            //  console.log('payload', payload);
            state['search'] = payload;
        },
        ClearSearch: (state) => {
            state.searchSuggestions = [];
            state.search = '';
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getTopBrandsData.fulfilled, (state, { payload }) => {
            // console.log("Payload-----------", payload);
            state.isLoading = false;
            state.topBrandData = payload;
        });
        builder.addCase(searchData.fulfilled, (state, { payload }) => {
            // console.log("Payload-----------", payload);
            // state.searchSuggestions = payload?.data?.productList;
        });

        builder.addCase(elasticSearchData.fulfilled, (state, { payload }) => {
            state.searchSuggestions = payload?.data?.data;
        });
        builder.addCase(getFeaturedData.fulfilled, (state, { payload }) => {
            state.featuredData = payload;
        });
        builder.addCase(getBestSellingData.fulfilled, (state, { payload }) => {
            state.bestSellingData = payload;
        });
        builder.addCase(
            getBestSellingProduct.fulfilled,
            (state, { payload }) => {
                state.bestSellingProduct = ManipualteBestSellingData(
                    payload.data
                );
            }
        );

        builder.addCase(getBestSellingCategoryProduct.pending, (state) => {
            state.bestSellingCategory = [];
        });

        builder.addCase(
            getBestSellingCategoryProduct.fulfilled,
            (state, { payload }) => {
                state.bestSellingCategory = ManipualteBestSellingData(
                    payload?.data
                );
            }
        );

        builder.addCase(getFeaturedProduct.fulfilled, (state, { payload }) => {
            state.featuredProduct = payload?.data;
        });
        builder.addCase(
            getConsiousWallProduct.fulfilled,
            (state, { payload }) => {
                state.consiousWallProduct = ManipualteBestSellingData(
                    payload?.data
                );
            }
        );
        builder.addCase(
            getInroducingProduct.fulfilled,
            (state, { payload }) => {
                state.introducingProduct = payload?.data;
            }
        );
        builder.addCase(
            getCrazyDealsProduct.fulfilled,
            (state, { payload }) => {
                state.crazyDeals = payload?.data?.list;
            }
        );
        builder.addCase(getBannerData.fulfilled, (state, { payload }) => {
            state.mainBanners = payload?.data?.list;
        });
    },
});

export const { SetElasticSearch, ClearSearch } = homeSlice.actions;
export default homeSlice.reducer;
