export const GET_ADDRESS_LIST_ENDPOINT = '/address';

export const ADD_ADDRESS_ENDPOINT = '/address/add';

export const UPDATE_ADDRESS_ENDPOINT = '/address/'; //address/:id

export const DELETE_ADDRESS_ENDPOINT = '/address/'; //address/:id

export const GET_ADDRESS_DETAILS = '/address/'; //address/:id

export const GET_STATES_ENDPOINT = '/state'; //states api

export const GET_CITY_ENDPOINT = '/city'; //states api
