import toast from 'react-hot-toast';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    deleteApi,
    getApi,
    patchApi,
    postApi,
} from '../../../scripts/api-services';
import {
    ADD_ADDRESS_ENDPOINT,
    DELETE_ADDRESS_ENDPOINT,
    GET_ADDRESS_DETAILS,
    GET_ADDRESS_LIST_ENDPOINT,
    GET_CITY_ENDPOINT,
    GET_STATES_ENDPOINT,
    UPDATE_ADDRESS_ENDPOINT,
} from '@/constants/endpoints/addressEndPoints';
import { HOME_DELIVERY, STORE_PICKUP } from '@/constants/payment-constant';
import { Store } from '../checkout-slice';

export const getStatesData = createAsyncThunk(
    'address/getStatesData',
    async () => {
        try {
            const response = await getApi(GET_STATES_ENDPOINT);
            return response;
        } catch (error) {
            console.log('Error fetch states API', error);
            return Promise.reject(error);
        }
    }
);

export const getCityData = createAsyncThunk(
    'address/getCityData',
    async ({ stateId }: any) => {
        try {
            const response = await postApi(GET_CITY_ENDPOINT, {
                stateId,
            });
            return response.data;
        } catch (error) {
            console.log('Error fetch states API', error);
            return Promise.reject(error);
        }
    }
);

export const getAddressData = createAsyncThunk(
    'address/getAddressData',
    async (payload, { getState }) => {
        const { useStoreId = true } = payload || {};

        try {
            const { deliveryType, storesForHD, selectedStoreIdForPickup } =
                getState().checkout_store;

            let storeIds;

            if (useStoreId) {
                storeIds = [];

                if (deliveryType === HOME_DELIVERY) {
                    if (storesForHD.default?._id) {
                        storeIds.push(storesForHD.default?._id);
                    }

                    storesForHD.others?.forEach((store: Store) => {
                        storeIds.push(store._id);
                    });

                    storesForHD.storeWithDifferentBatch?.forEach(
                        (store: Store) => {
                            storeIds.push(store._id);
                        }
                    );
                }

                if (deliveryType === STORE_PICKUP) {
                    storeIds = [selectedStoreIdForPickup];
                }
            }

            const response = await postApi(GET_ADDRESS_LIST_ENDPOINT, {
                storeId: storeIds,
            });
            return response.data;
        } catch (error) {
            console.log('Error fetch address API', error);
            return Promise.reject(error);
        }
    }
);

export const addAddress = createAsyncThunk(
    'address/addAddress',
    async (reqData: any) => {
        try {
            if (reqData.hasOwnProperty('email') && reqData?.email === '') {
                delete reqData.email;
            }
            const response = await postApi(ADD_ADDRESS_ENDPOINT, reqData);
            toast.success('Address created successfully');
            return response;
        } catch (error) {
            console.error('Error add address API', error);
            return error;
        }
    }
);

export const updateAddress: any = createAsyncThunk(
    'address/updateAddress',
    async (reqData: any) => {
        try {
            // if(reqData.hasOwnProperty("email") && reqData?.email === ""){
            //   delete reqData.email
            // }
            const response = await patchApi(
                `${UPDATE_ADDRESS_ENDPOINT}${reqData?.id}`,
                reqData
            );
            return response;
        } catch (error) {
            console.log('Error update address item API', error);
            return Promise.reject(error);
        }
    }
);

export const deleteAddress: any = createAsyncThunk(
    'address/deleteAddress',
    async (id: string) => {
        try {
            const response = await deleteApi(
                `${DELETE_ADDRESS_ENDPOINT}${id}`
            );
            toast.success('Address deleted successfully');
            return response;
        } catch (error) {
            console.log('Error delete address item API', error);
            return Promise.reject(error);
        }
    }
);

export const getAddressDetails = createAsyncThunk(
    'address/getAddressDetails',
    async (id: string) => {
        try {
            const response = await getApi(`${GET_ADDRESS_DETAILS}${id}`);
            return response;
        } catch (error) {
            console.log('Error delete cart item API', error);
            return Promise.reject(error);
        }
    }
);
