import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApi, postApi } from '../../../scripts/api-services';
import {
    GET_PRODUCT_COUNT,
    GET_PRODUCT_LIST,
} from '@/constants/endpoints/productEndpoint';
import {
    GET_PRODUCT_DETAILS,
    GET_SIMILAR_PRODUCTS,
} from '@/constants/endpoints/productDetailsendPoint';

interface ProductDataArgs {
    page?: number | null;
    pageSize?: number | null;
    search?: string | null;
    firstCategoryId?: string | null;
    secondCategoryId?: string | null;
    thirdCategoryId?: any;
    attributes?: any;
    storeId?: string | null;
    attributeSlugs?: any;
    salesPrice: any;
    discount: any;
    userId?: string;
}

interface DetailsProduct {
    masterSku: string;
}

export const getProductList: any = createAsyncThunk(
    'product/getProductList',
    async (reqData: ProductDataArgs, { dispatch }) => {
        try {
            const response = await postApi(GET_PRODUCT_LIST, reqData);

            return response?.data?.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

export const getProductCount: any = createAsyncThunk(
    'product/getProductCount',
    async (reqData: ProductDataArgs, { dispatch }) => {
        try {
            const response = await postApi(GET_PRODUCT_COUNT, reqData);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

export const productDetailsData = createAsyncThunk(
    'product/fetchProductsDetails',
    async ({ masterSku }: DetailsProduct) => {
        try {
            const response = await getApi(
                `${GET_PRODUCT_DETAILS}?sku=${masterSku}`
            );

            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

export const getSimilarProducts = createAsyncThunk(
    'product/similar-product',
    async (thirdCategoryId: any, { getState }: any) => {
        try {
            const { storeId } = getState().product_store;
            const reqData = {
                storeId: storeId,
                thirdCategoryId: thirdCategoryId,
            };

            const response = await postApi(GET_SIMILAR_PRODUCTS, reqData);

            return response?.data;
        } catch (error) {
            console.log('Error in similar-product API', error);
            return Promise.reject(error);
        }
    }
);

export function ManipualteSimilarData(data: any) {
    return data?.flatMap((item: any) => {
        if (item?.productType === 'variable') {
            return {
                id: item?._id,
                path: item?.variantImages?.[0] || item?.images?.[0],
                productId: item?.productId,
                masterProductId: item?.productId,
                productVariantId: item?.variantId,
                isOutOfStock: item?.quantity === 0,
                width: 100,
                sku: item?.masterSku,
                variantSku: item?.variantSku,
                height: 100,
                brand: item?.brandName,
                productName: item?.productName,
                type: item?.type || item?.productType,
                discountedPrice: item?.discountedPrice,
                mrp: item?.mrp,
                discount: item?.discount,
                variantIds: item?.data,
                variantId: item?.variantId,
            };
        } else {
            return {
                id: item?._id,
                path: item?.images[0],
                width: 100,
                sku: item?.masterSku,
                productId: item?._id,
                isOutOfStock: item?.quantity === 0,
                height: 100,
                masterProductId: item?._id,
                brand: item?.brandName,
                productName: item?.productName,
                type: item?.type || item?.productType,
                discountedPrice: item?.discountedPrice,
                mrp: item?.mrp,
                discount: item?.discount,
            };
        }
    });
}
