import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import authReducer from '@/store/slices/authSlice';
import categorySlice from './slices/categorySlice';
import brandSlice from './slices/brand-slice';
import productSlice from './slices/product-slice';
import { persistReducer } from 'redux-persist';
import homeSlice from './slices/home-slice';
import filterSlice from './slices/filter-slice';
import cartSlice from './slices/cart-slice';
import addressSlice from './slices/address-slice';
import prescriptionSlice from './slices/prescription-slice';
import attributeSlice from './slices/attribute-slice';
import checkoutSlice from './slices/checkout-slice';
import orderSlice from './slices/order-slice';
import wishlistSlice from './slices/wishlist-slice';
import reviewSlice from './slices/review-slice';
import storeSlice from './slices/store-slice';

const persistConfig = {
    key: 'root', //key can be anything
    storage,
    whitelist: ['auth', 'category_store'],
    blacklist: ['filter_store'],
};

const rootReducer = combineReducers({
    auth: authReducer,
    category_store: categorySlice,
    brand_store: brandSlice,
    product_store: productSlice,
    home_store: homeSlice,
    filter_store: filterSlice,
    cart_store: cartSlice,
    address_store: addressSlice,
    prescription_store: prescriptionSlice,
    attribute_store: attributeSlice,
    checkout_store: checkoutSlice,
    order_store: orderSlice,
    wishlist_store: wishlistSlice,
    review_store: reviewSlice,
    store_store: storeSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: true,
            serializableCheck: false,
            immutableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
