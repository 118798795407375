import { createSlice } from '@reduxjs/toolkit';

import { getOrderStatus, getOrders } from './actions/order-action';

interface OrderState {
    isLoading: boolean;
    status: string | null;
    orderData: any[];
    page: number | null;
    orderCount: number | null;
    pendingOrderData: [];
    confirmOrderData: [];
    shippedOrderData: [];
    deliveredOrderData: [];
    cancelledOrderData: [];
}

const initialState: OrderState = {
    isLoading: false,
    orderData: [],
    pendingOrderData: [],
    status: '',
    page: 1,
    orderCount: 0,
    confirmOrderData: [],
    shippedOrderData: [],
    deliveredOrderData: [],
    cancelledOrderData: [],
};

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.page = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getOrders.fulfilled, (state, action) => {
            state.isLoading = false;
            state.status = 'fulfilled';
            if (Number(state.page) === 1) {
                state.orderData = action.payload?.data?.data.list;
            } else {
                state.orderData = [...state.orderData, ...action.payload?.data?.data.list];
            }
            // (state.orderData = action.payload?.data?.data.list),
                (state.orderCount = action.payload?.data?.data?.count[0]?.total);
            const pendingOrders = action.payload?.data?.data?.list?.filter(
                (item: any) => item.status === 'pending'
            );
            const confirmOrderData = action.payload?.data?.data?.list?.filter(
                (item: any) => item.status === 'confirmed'
            );
            const shippedOrderData = action.payload?.data?.data?.list?.filter(
                (item: any) => item.status === 'packed'
            );
            const deliveredOrderData = action.payload?.data?.data?.list?.filter(
                (item: any) => item.status === 'delivered'
            );
            const cancelledOrderData = action.payload?.data?.data?.list?.filter(
                (item: any) => item.status === 'cancelled'
            );
            state.pendingOrderData = pendingOrders;
            state.confirmOrderData = confirmOrderData;
            state.shippedOrderData = shippedOrderData;
            state.deliveredOrderData = deliveredOrderData;
            state.cancelledOrderData = cancelledOrderData;
        });
        builder.addCase(getOrders.pending, (state, action) => {
            state.isLoading = true;
            state.status = 'pending';
        });
        builder.addCase(getOrders.rejected, (state, action) => {
            state.isLoading = false;
            state.status = 'rejected';
            state.orderData = [];
            state.orderCount = 0;
        });
        builder.addCase(getOrderStatus.pending, (state, action) => {
            const {orderId} = action.meta.arg
            const currentOrder = state.orderData.find((order) => {
                return order.orderId === orderId;
            });

          currentOrder.isStatusCheckInProgress = true;
        });
        builder.addCase(getOrderStatus.fulfilled, (state, action) => {
            const { type, orderId, orderStatusCheckedAt } = action.payload;
            const currentOrderIndex = state.orderData.findIndex((order) => {
                return order.orderId === orderId
            })

            if (type === 'pidge-detail') {
                // const { status, updated_at, fulfillment } = action.payload;
                const { pidgeOrderStatus } = action.payload;
                const { status, deliveryStatus, track_code } = pidgeOrderStatus;

                
                state.orderData[currentOrderIndex].orderStatus = status;
                // state.orderData[currentOrderIndex].orderUpdatedAt = updated_at;
                // state.orderData[currentOrderIndex].fulfillment = fulfillment;
                state.orderData[currentOrderIndex].deliveryStatusNew =
                deliveryStatus;
                state.orderData[currentOrderIndex].trackCode = track_code;
            } else {
                // this case is not being used as of now
                const { newOrderData } = action.payload;
                state.orderData[currentOrderIndex] = newOrderData;
            }
                
            state.orderData[currentOrderIndex].orderStatusCheckedAt =
                orderStatusCheckedAt;
            state.orderData[currentOrderIndex].isStatusCheckInProgress =
                false;
        });
        builder.addCase(getOrderStatus.rejected, (state, action) => {
             const { orderId } = action.meta.arg;
             const currentOrder = state.orderData.find((order) => {
                 return order.orderId === orderId;
             });

             currentOrder.isStatusCheckInProgress = false;
        });
        
    },
});

export const { setCurrentPage } = orderSlice.actions;

export default orderSlice.reducer;
