import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    ManipualteSimilarData,
    getProductCount,
    getProductList,
    getSimilarProducts,
    productDetailsData,
} from './actions/product-action';
import { boolean } from 'zod';

interface ProductData {
    isLoading: boolean;
    productList: any;
    filters: [];
    allProductData: [];
    productDetailsData: [];
    status: string;
    search: string;
    count: number;
    currentPage: number;
    storeId: string;
    discount: string;
    productVariantId: string;
    variantsDetails: {};
    productDetails: {}; // for variant if there
    hasProduct: boolean;
    similarProducts: [];
    productCount: number;
    message: string;
}

const initialState: ProductData = {
    isLoading: false,
    productList: [],
    filters: [],
    allProductData: [],
    productDetailsData: [],
    status: '',
    search: '',
    count: 0,
    currentPage: 1,
    storeId: '',
    discount: '',
    productVariantId: '',
    variantsDetails: {},
    productDetails: {}, // for variant if there
    hasProduct: true,
    similarProducts: [],
    productCount: 0,
    message: '',
};

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        SetProductDetails: (state, action) => {
            state.productDetails = action.payload;
        },
        SetProductSearch: (state, { payload }) => {
            state['search'] = payload.value;
        },
        SetLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        SetProductListData: (state, { payload }) => {
            state.productList = payload;
        },
        SetCurrentPage: (state, { payload }) => {
            state.currentPage = payload;
        },
        ClearCurrentPage: (state) => {
            state.currentPage = 1;
        },
        ClearProductList: (state) => {
            state.productList = [];
            state.currentPage = 1;
        },
        SetStoreId: (state, action) => {
            state.storeId = action.payload;
        },
        SetDiscountData: (state, { payload }) => {
            state.discount = payload;
        },
        SetProductVariantId: (state, action) => {
            state.productVariantId = action.payload;
        },
        SetProductVariantDetails: (state, { payload }) => {
            const { key, value } = payload;
            (state.variantsDetails as any)[key] = value;
        },
        setWishlistProductTrue: (state, action) => {
            state.productList = state.productList.map((data: any) => {
                if (
                    action.payload.productType === 'simple' &&
                    data?.productId === action.payload.productId
                ) {
                    return {
                        ...data,
                        isInWishlist: true,
                    };
                } else if (
                    action.payload.productType === 'variable' &&
                    data?.variantId === action.payload.productId
                ) {
                    return {
                        ...data,
                        isInWishlist: true,
                    };
                }
                return data;
            });
        },
        setWishlistProductFalse: (state, action) => {
            state.productList = state.productList.map((data: any) => {
                if (
                    action.payload.productType === 'simple' &&
                    data?.productId === action.payload.productId
                ) {
                    return {
                        ...data,
                        isInWishlist: false,
                    };
                } else if (
                    action.payload.productType === 'variable' &&
                    data?.variantId === action.payload.productId
                ) {
                    return {
                        ...data,
                        isInWishlist: false,
                    };
                }
                return data;
            });
        },
    },
    extraReducers: (builder) => {
        // product listing api
        builder.addCase(getProductList.pending, (state) => {
            state.isLoading = true;
            state.status = 'pending';
            state.message = '';
        });
        builder.addCase(
            getProductList.fulfilled,
            (state, { payload }: PayloadAction<any>) => {
                state.isLoading = false;
                if (Number(state.currentPage) === 1) {
                    state.productList = payload;
                } else {
                    state.productList = [...state.productList, ...payload];
                }
                if (
                    state.productList.length === 0
                ) {
                    state.message = 'No product found';
                } else {
                    state.message = '';
                }
                if (payload?.length === 0) {
                    state.hasProduct = false;
                } else {
                    state.hasProduct = true;
                }
                state.status = 'succeeded';
            }
        );
        builder.addCase(getProductList.rejected, (state) => {
            state.isLoading = false;
            state.status = 'rejected';
            state.message = 'No Products Found';
        });

        // product count api
        builder.addCase(getProductCount.pending, (state) => {
            state.status = 'pending';
        });
        builder.addCase(getProductCount.rejected, (state) => {
            state.status = 'rejected';
        });
        builder.addCase(
            getProductCount.fulfilled,
            (state, { payload }: PayloadAction<any>) => {
                state.status = 'fulfilled';
                state.productCount = payload?.data?.data;
            }
        );

        // product details api
        builder.addCase(productDetailsData.pending, (state) => {
            state.isLoading = true;
            state.status = 'pending';
            state.productDetailsData = [];
        });
        builder.addCase(productDetailsData.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.status = 'succeeded';
            state.productDetailsData = payload;
        });
        builder.addCase(productDetailsData.rejected, (state) => {
            state.isLoading = false;
            state.status = 'rejected';
        });

        // similar products api
        builder.addCase(getSimilarProducts.pending, (state) => {
            state.status = 'pending';
        });
        builder.addCase(getSimilarProducts.fulfilled, (state, { payload }) => {
            state.status = 'fulfilled';
            state.similarProducts = ManipualteSimilarData(payload.data);
        });
        builder.addCase(getSimilarProducts.rejected, (state) => {
            state.status = 'rejected';
        });
    },
});

export const {
    SetProductDetails,
    SetProductVariantId,
    SetStoreId,
    SetProductSearch,
    SetCurrentPage,
    ClearCurrentPage,
    SetProductListData,
    ClearProductList,
    SetDiscountData,
    SetProductVariantDetails,
    setWishlistProductTrue,
    setWishlistProductFalse,
    SetLoading,
} = productSlice.actions;

export default productSlice.reducer;
