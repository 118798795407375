import { createSlice } from '@reduxjs/toolkit';
import {
    addCartItem,
    getCartData,
    getCartItemCount,
    updateStoreIdInCart,
} from './actions/cart-actions';

interface CartState {
    cartId: string | null;
    isLoading: boolean;
    status: string | null;
    cartCount: number | null;
    grandTotal: number | null;
    subTotal: number | null;
    totalGst: number | null;
    cartData: [];
    totalDiscountedAmount: number | null;
    prescriptionRequiredItems: [];
    hasPrescriptionRequiredItems: boolean;
    prescriptionRequiredItemsCount: number;
    shippingCharges: number;
    priceDifference: number;
}

const initialState: CartState = {
    isLoading: false,
    cartId: '',
    cartData: [],
    cartCount: 0,
    grandTotal: 0,
    subTotal: 0,
    totalGst: 0,
    status: '',
    totalDiscountedAmount: 0,
    prescriptionRequiredItems: [],
    hasPrescriptionRequiredItems: false,
    prescriptionRequiredItemsCount: 0,
    shippingCharges: 0,
    priceDifference: 0
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(getCartData.pending, (state, action) => {
            state.isLoading = true;
            state.status = 'pending';
        });
        builder.addCase(getCartData.rejected, (state, action) => {
            state.isLoading = false;
            state.status = 'rejected';
        });
        builder.addCase(getCartData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.cartId = action?.payload?.data[0]?._id;
            state.cartData = action?.payload?.data[0]?.items;
            state.grandTotal = action?.payload?.data[0]?.grandTotal;
            state.subTotal = action?.payload?.data[0]?.subTotal;
            state.totalGst = action.payload?.data[0]?.totalGst;
            state.shippingCharges = action.payload?.data[0]?.shippingCharges;

            const data = action?.payload?.data[0]?.items;
            const totalDiscountedAmount = data?.reduce(
                (total: any, item: { totalDiscountedAmount: any }) =>
                    total + item.totalDiscountedAmount,
                0
            );
            state.totalDiscountedAmount = totalDiscountedAmount;
            state.status = 'fulfilled';

            // prescription required items
            const prescriptionItems = data?.filter(
                (e: any) => e?.prescriptionRequired === true
            );
            state.prescriptionRequiredItems = prescriptionItems;

            if (prescriptionItems?.length > 0) {
                state.hasPrescriptionRequiredItems = true;
                state.prescriptionRequiredItemsCount =
                    prescriptionItems?.length;
            }
        });

        builder.addCase(getCartItemCount.fulfilled, (state, action) => {
            state.isLoading = false;
            state.cartCount = action?.payload?.data?.count;
            state.status = 'fulfilled';
        });

        builder.addCase(addCartItem.pending, (state, action) => {
            state.isLoading = true;
            state.status = 'pending';
        });

        builder.addCase(addCartItem.rejected, (state, action) => {
            state.isLoading = false;
            state.status = 'rejected';
        });

        builder.addCase(addCartItem.fulfilled, (state, action) => {
            state.isLoading = false;
            state.status = 'fulfilled';
        });

        builder.addCase(updateStoreIdInCart.fulfilled, (state, action) => {
            state.priceDifference = action?.payload?.data?.priceDifference;
        });
    },
});

export const {} = cartSlice.actions;

export default cartSlice.reducer;
