import { GET_ATTRIBUTE_LIST_ENDPOINT } from '@/constants/endpoints/attributeEndPoints';
import { postApi } from '@/scripts/api-services';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAttributeList = createAsyncThunk(
    'attribute/getAttributeList',
    async () => {
        try {
            const response = await postApi(GET_ATTRIBUTE_LIST_ENDPOINT);
            return response;
        } catch (error) {
            console.log('Error fetch  API', error);
            return Promise.reject(error);
        }
    }
);
