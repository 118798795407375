export const ALL_ORDER_ENDPOINT = '/order/all';

export const ORDER_DETAIL = '/order';

export const RETURN_REASON = '/order/return-reason';

export const RETURN_ORDER = '/order/return';

export const ORDER_STATUS = '/pidge/track-order';

export const DELIVERY_STATUS = '/order/delivery-status';
