import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GetReviewList } from './actions/review-action';

interface ReviewDetailsPayload {
    key: string;
    value: any;
}

interface ReviewState {
    reviewList: any[];
    reviewDetailsList: any[];
    reviewList_count: null | number;
    reviewDetails: { [key: string]: any };
}

const initialState: ReviewState = {
    reviewList: [],
    reviewDetailsList: [],
    reviewList_count: null,
    reviewDetails: {},
};

const reviewSlice = createSlice({
    name: 'review_store',
    initialState,
    reducers: {
        SetReviewDetails: (
            state,
            { payload }: PayloadAction<ReviewDetailsPayload>
        ) => {
            const { key, value } = payload;
            state.reviewDetails[key] = value;
        },
        ClearReviewDetails: (state) => {
            state.reviewDetails = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(GetReviewList.fulfilled, (state, { payload }) => {
            // console.log("Payload---------------", payload)
            // state.bannerList_count = payload?.data?.count;
            state.reviewList = payload?.data;
        });
    },
});

export const { SetReviewDetails, ClearReviewDetails } = reviewSlice.actions;
export default reviewSlice.reducer;
