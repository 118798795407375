import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setCookie, deleteCookie } from 'cookies-next';
interface AuthState {
    isAuthenticated: boolean;
    user: User | null;
    accessToken: string | null;
    emailMobile: string | null;
    currentStep: string | null;
    signUpDetail: any | null;
    testingOtp: string | null;
    profileData: any;
}

export interface User {
    firstName: string;
    lastName: string;
    type: string;
    mobile: string;
    emailVerified: boolean;
    role: string;
}

const initialState: AuthState = {
    isAuthenticated: false,
    accessToken: null,
    user: null,
    emailMobile: '',
    currentStep: '',
    signUpDetail: null,
    testingOtp: '',
    profileData: [],
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (
            state,
            action: PayloadAction<{ accessToken: string; user: User }>
        ) => {
            state.isAuthenticated = true;
            state.user = action.payload.user;
            state.accessToken = action.payload.accessToken;
            setCookie('accessToken', action.payload.accessToken);
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = null;
            state.accessToken = null;

            deleteCookie('accessToken');
        },
        setProfileDetails: (state, action) => {
            state.profileData = action.payload;
        },
        SetLoginEmailMobile: (state, { payload }) => {
            state.emailMobile = payload.emailMobile;
        },
        SetCurrentSignUpStep: (state, { payload }) => {
            state.currentStep = payload;
        },
        SetSignUpDetails: (state, { payload }) => {
            state.signUpDetail = payload;
        },
        SetTestingOtp: (state, { payload }) => {
            state.testingOtp = payload;
        },
        ClearSignUp: (state) => {
            state.signUpDetail = null;
            state.currentStep = '';
            state.testingOtp = '';
        },
    },
});

export const {
    loginSuccess,
    logout,
    SetLoginEmailMobile,
    SetCurrentSignUpStep,
    SetSignUpDetails,
    ClearSignUp,
    SetTestingOtp,
    setProfileDetails,
} = authSlice.actions;

export default authSlice.reducer;
