import { createSlice } from '@reduxjs/toolkit';
import {
    getCustomerPrescriptionData,
    uploadPrescription,
} from './actions/prescription-action';

interface PrescriptionState {
    isLoading: boolean;
    status: string | null;
    prescriptionData: [];
}

const initialState: PrescriptionState = {
    isLoading: false,
    prescriptionData: [],
    status: '',
};

export const prescriptionSlice = createSlice({
    name: 'prescription',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(uploadPrescription.pending, (state) => {
            state.isLoading = true;
            state.status = 'pending';
        });
        builder.addCase(uploadPrescription.fulfilled, (state) => {
            state.isLoading = false;
            state.status = 'fulfilled';
        });
        builder.addCase(uploadPrescription.rejected, (state) => {
            state.isLoading = false;
            state.status = 'reject';
        });
        builder.addCase(
            getCustomerPrescriptionData.fulfilled,
            (state, action) => {
                state.isLoading = false;
                state.prescriptionData = action?.payload?.data;
                state.status = 'fulfilled';
            }
        );
        builder.addCase(
            getCustomerPrescriptionData.pending,
            (state, action) => {
                state.isLoading = false;
                state.prescriptionData = [];
                state.status = 'pending';
            }
        );
    },
});

export const {} = prescriptionSlice.actions;

export default prescriptionSlice.reducer;
