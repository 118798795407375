import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteApi, getApi, postApi } from '../../../scripts/api-services';
import {} from '../../../constants/endpoints/categoryEndpoints';
import {
    DELETE_PRESCRIPTION,
    GET_PRESCRIPTION_ENDPOINT,
    UPLOADE_PRESCRIPTION_ENDPOINT,
} from '@/constants/endpoints/prescriptionEndPoints';
import toast from 'react-hot-toast';

// Define the type for the headers object
interface Headers {
    'Content-Type': string;
}

export const getCustomerPrescriptionData: any = createAsyncThunk(
    'prescription/categoryData',
    async () => {
        try {
            const response = await getApi(GET_PRESCRIPTION_ENDPOINT);
            // console.log("Response GET PRESCRIPTION", response.data);
            return response;
        } catch (error) {
            console.log('Error in get prescription', error);
            return Promise.reject(error);
        }
    }
);

export const uploadPrescription: any = createAsyncThunk(
    'category/uploadPrescription',
    async (reqData: any) => {
        try {
            const response = await postApi(
                `${UPLOADE_PRESCRIPTION_ENDPOINT}`,
                reqData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            console.log('response upload prescription', response);
            return response;
        } catch (error) {
            console.log('Error upload prescription', error);
            return Promise.reject(error);
        }
    }
);

export const deleteCustomerPrescription: any = createAsyncThunk(
    'cart/deleteCartItem',
    async (_id: any, { dispatch }: any) => {
        try {
            console.log('Action delete prescription data', _id);
            const response = await deleteApi(`${DELETE_PRESCRIPTION}${_id}`);
            toast.success('successfully deleted prescription');
            dispatch(getCustomerPrescriptionData());
            return response;
        } catch (error) {
            console.log('Error delete cart item API', error);
            return Promise.reject(error);
        }
    }
);
