import {
    CREATE_REVIEW,
    REVIEW_LIST,
} from '@/constants/endpoints/reviewEndpoints';
import { getApi, postApi } from '@/scripts/api-services';
import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

/*---------------- Get Review List ----------------- */

export const GetReviewList = createAsyncThunk(
    'review-list',
    async ({ id }: any) => {
        try {
            const res = await getApi(`${REVIEW_LIST}/${id}`);
            return res;
        } catch (error: any) {
            console.error(error);
            toast.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

/*---------------- Create Review -------------------*/

export const CreateReview = createAsyncThunk(
    'CreateReview',
    async ({ payload }: any, { dispatch }) => {
        try {
            const res = await postApi(`${CREATE_REVIEW}`, {
                ...payload,
            });
            return res.data;
        } catch (error: any) {
            console.error(error);
            toast.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);
