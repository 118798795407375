import { createSlice } from '@reduxjs/toolkit';
import { getAttributeList } from './actions/attribute-action';

interface AttributeState {
    isLoading: boolean;
    status: string | null;
    attributeData: any | null;
}

const initialState: AttributeState = {
    isLoading: false,
    attributeData: [],
    status: '',
};

export const attributeSlice = createSlice({
    name: 'attribute',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(getAttributeList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.attributeData = action?.payload?.data?.data;
            state.status = 'fulfilled';
        });
    },
});

export const {} = attributeSlice.actions;

export default attributeSlice.reducer;
